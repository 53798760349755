import React from "react";
import {Helmet} from "react-helmet";
import "./styles.css";
/*import ReactDOM from "react-dom";*/

const Profile = () => {
    return <div className="content">
                <div>
                Ясик - это самый лучший город на Земле!
                </div>
                <div className="ya-site-form ya-site-form_inited_no" data-bem="{&quot;action&quot;:&quot;https://yandex.ru/search/site/&quot;,&quot;arrow&quot;:false,&quot;bg&quot;:&quot;#cccccc&quot;,&quot;fontsize&quot;:12,&quot;fg&quot;:&quot;#000000&quot;,&quot;language&quot;:&quot;ru&quot;,&quot;logo&quot;:&quot;rb&quot;,&quot;publicname&quot;:&quot;Сайт phpcafe.ru&quot;,&quot;suggest&quot;:true,&quot;target&quot;:&quot;_self&quot;,&quot;tld&quot;:&quot;ru&quot;,&quot;type&quot;:2,&quot;usebigdictionary&quot;:false,&quot;searchid&quot;:2917649,&quot;input_fg&quot;:&quot;#000000&quot;,&quot;input_bg&quot;:&quot;#ffffff&quot;,&quot;input_fontStyle&quot;:&quot;normal&quot;,&quot;input_fontWeight&quot;:&quot;normal&quot;,&quot;input_placeholder&quot;:&quot;Что ищем?&quot;,&quot;input_placeholderColor&quot;:&quot;#0000cc&quot;,&quot;input_borderColor&quot;:&quot;#7f9db9&quot;}">
                    <form action="https://yandex.ru/search/site/" method="get" target="_self" accept-charset="utf-8">
                        <input type="hidden" name="searchid" value="2917649"/><input type="hidden" name="l10n" value="ru"/>
                        <input type="hidden" name="reqenc" value=""/><input type="search" name="text" value=""/>
                        <input type="submit" value=" Найти"/>
                        </form></div>


                <Helmet>
              <script src="./search.js" type="text/jsx" />
            </Helmet>
    
            </div>
}

export default Profile;